<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-input
        v-model.trim="studentId"
        placeholder="请输入学号"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-input
        v-model.trim="classNameValue"
        placeholder="请输入班级名称"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-select
        v-model="seleStute"
        placeholder="选择状态"
        class="tb-smselect"
      >
        <el-option
          v-for="item in StuteOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
      <el-button  v-if="activeName == 'first'" type="info" size="medium" @click="oneKeyOpen" class="tb-button">一键开放视频</el-button>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="first == 'show'" label="补课审核" name="first">
      </el-tab-pane>
      <el-tab-pane v-if="second == 'show'" label="我的申请" name="second"></el-tab-pane>
    </el-tabs>
    <el-table
      border
      fit
      highlight-current-row
      :data="activeName == 'first'?dataListAll:dataListMyaudit"
      class="tb-list"
      @selection-change="handleSeleChange"
    >
      <el-table-column v-if="activeName == 'first'" type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="学员" align="center" prop="loginName">
        <template slot-scope="scope">
          <span class="tb-span">{{ scope.row.userName}}[{{ scope.row.loginName}}]</span>
        </template>
      </el-table-column>
      <el-table-column label="班级名称" align="center" prop="className">
        <template slot-scope="scope">
          <span class="tb-span">{{ scope.row.className}}[{{ scope.row.lessonStartTime | formatDateStart }}~{{ scope.row.lessonEndTime | formatDateend }}]</span>
        </template>
      </el-table-column>
      <el-table-column label="申请人" align="center" width="100" prop="operator">
      </el-table-column>
      <el-table-column label="申请时间" align="center">
        <template slot-scope="scope">
          <span class="tb-span">{{scope.row.createTime.split(',')[0] | formatDateStart }}</span>
        </template>
      </el-table-column>
      <el-table-column label="课程课节" align="center" prop="courseTitle">
        <template slot-scope="scope">
          <span class="tb-span">{{scope.row.courseTitle}}[{{scope.row.courseUnitTitle}}]</span>
        </template>
      </el-table-column>
      <el-table-column label="视频标题" align="center" prop="attachmentTitle"></el-table-column>
      <el-table-column label="有效期" align="center">
        <template slot-scope="scope">
          <span class="title-link" @click="editTime(scope.row)">{{scope.row.startTime|formatDateStart }}—{{scope.row.endTime|formatDateStart }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-row v-if="activeName == 'first'">
          <el-button
            v-if="scope.row.status == '待审核'"
            size="small"
            type="warning"
            @click="reviewCourse(scope.row)"
          >{{scope.row.status}}</el-button>
          <el-button
            v-if="scope.row.status == '未通过'"
            size="small"
            type="danger"
            @click="reviewCourse(scope.row)"
          >{{scope.row.status}}</el-button>
          <el-button
            v-if="scope.row.status == '已通过'"
            size="small"
            type="success"
            @click="reviewCourse(scope.row)"
          >{{scope.row.status}}</el-button>
          </el-row>
          <span v-else>{{scope.row.status}}</span>
        </template>
      </el-table-column>
    </el-table>
        <el-dropdown v-if="activeName == 'first'" trigger="click" class="tb-dropdown">
          <el-button type="info" size="medium">
            更多操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="enableAccount">
              <i class=""></i>批量通过
            </el-dropdown-item>
            <el-dropdown-item @click.native="disabledAccount">
              <i class=""></i>批量拒绝
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="paginationall.currentPage"
          :page-sizes="paginationall.pageSizes"
          :page-size="paginationall.pageSize"
          :layout="paginationall.layout"
          :total="this.totalall"
        ></el-pagination>
    <!-- 课件补课视频 dialog -->
    <edit-course-ware-time
      :editCourseData="editCourseData"
      @updateView="getClassListAll(1)"
      :show="editCourseFlag"
      @close="editCourseFlag=false"
    ></edit-course-ware-time>
    <!--  -->
    <key-open :show="keyOpenDialog" @close="keyOpenDialog=false" @getClassListAll="getClassListAll"></key-open>
    </el-row>
</template>

<script>
import { formatDate } from '@/utils/formatDate'
import editCourseWareTime from '@/components/senate/editCourseWareTime'
import KeyOpen from '@/components/senate/key-open'
import { getListVideoReview, reviewAttachmentBat,listVideoReviewByOperator } from '@/api/senate/lesson'

export default {
  filters: {
    formatDate (time) {
      if (time == null || time === '') {
        return ''
      }
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm~')
    },
    formatDateStart (time) {
      if (time == null || time === '') {
        return ''
      }
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
    formatDateend (time) {
      if (time == null || time === '') {
        return ''
      }
      var date = new Date(time)
      return formatDate(date, 'hh:mm')
    }
  },
  data () {
    return {
      editCourseData: {},
      editCourseFlag: false,
      schoolValue: '',
      campus_id: '',
      studentId: '',
      schoolArray: [],
      activeName: 'first',
      totalall: 0,
      totalmyadit:0,
      paginationall: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: this.totalall
      },
      paginationMyaudit: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: this.totalmyadit
      },
      dataListAll: [],
      dataListMyaudit:[],
      userType: 0, // 0 老师，1校长，2教务主管
      classData: null,
      chooseDate: '',
      minStartTime: '',
      maxStartTime: '',
      first:'none',
      second:'none',
      pickerOptions2: {
        shortcuts: [
          {
            text: '当天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      classNameValue: '',
      seleStute: '',
      StuteOptions: [
        {
          label: '已通过',
          value: '已通过'
        },
        {
          label: '未通过',
          value: '未通过'
        },
        {
          label: '待审核',
          value: '待审核'
        }
      ],
      keyOpenDialog: false
    }
  },
  components: {
    editCourseWareTime,
    KeyOpen
  },
  created () {
    let perms = JSON.parse(localStorage.getItem('perms'))
    if(perms.indexOf("supplementary:supplementaryexam") != -1) {
      this.activeName = 'first'
      this.first = "show"
    }
    if(perms.indexOf("supplementary:myapplication") != -1 && perms.indexOf("supplementary:supplementaryexam") == -1) {
      this.activeName = 'second'
      this.second = "show"
    }else if(perms.indexOf("supplementary:myapplication") != -1 && perms.indexOf("supplementary:supplementaryexam") != -1){
      this.second = "show"
    }

    if(this.first === 'show') {
      this.getClassListAll(1)
    }else {
      this.getlistVideoReviewByOperator(1)
    }

  },
  methods: {
    handleClick (value) {
      this.paginationall.currentPage =1;
      if(this.activeName == 'first') {
        this.getClassListAll(1)
      }else if(this.activeName == 'second') {
        this.getlistVideoReviewByOperator(1)
      }
    },
    editTime (row) {
      this.editCourseData = row
      this.editCourseFlag = true
    },
    // 关闭
    close () {
      this.classData = null
    },

    getSchoolValue (val) {
      this.campus_id = val
    },

    // 全部分页
    handleCurrentChange (val) {
      this.paginationall.currentPage = val
      if(this.activeName == 'first') {
        this.getClassListAll(1)
      }else if(this.activeName == 'second') {
        this.getlistVideoReviewByOperator(1)
      }
    },
    handleSizeChange (val) {
      this.paginationall.pageSize = val
      if(this.activeName == 'first') {
        this.getClassListAll(1)
      }else if(this.activeName == 'second') {
        this.getlistVideoReviewByOperator(1)
      }
    },

    reviewCourse (row) {
      this.$confirm('是否通过此次补课申请提交', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '通过',
        cancelButtonText: '拒绝',
        type: 'warning'
      })
        .then(async () => {
          let attachmentId = []
          attachmentId = row.attachmentId.split(',')
          let enableList = []
          attachmentId.map((item, index) => {
            enableList.push({
              attachmentId: item,
              userId: row.userId,
              status: '已通过',
              startTime: row.startTime,
              endTime: row.endTime
            })
          })
          await reviewAttachmentBat(enableList)
          this.getClassListAll(1)
        })
        .catch(action => {
          if (action === 'cancel') {
            let attachmentId = []
            attachmentId = row.attachmentId.split(',')
            let enableList = []
            attachmentId.map((item, index) => {
              enableList.push({
                attachmentId: item,
                userId: row.userId,
                status: '未通过',
                startTime: row.startTime,
                endTime: row.endTime
              })
            })
            reviewAttachmentBat(enableList).then(res => {
              if (res.state === 'success') {
                this.getClassListAll(1)
              }
            })
          }
        })
    },
    // 获取老师排课的班级
    async getClassListAll (from) {
      // from  1正常全部班级，2搜索 3管辖班级 0897944018
      if (from === 2) {
        this.paginationall.currentPage = 1
      }
      const res = await getListVideoReview({
        pageNum: this.paginationall.currentPage,
        pageSize: this.paginationall.pageSize,
        needCount: true,
        className: this.classNameValue,
        sid: this.studentId,
        statusSet: this.seleStute ? [this.seleStute] : null
      })
      this.totalall = res.body.total
      this.dataListAll = res.body.list
    },

    // 获取老师排课的班级
    async getlistVideoReviewByOperator (from) {
      if (from === 2) {
        this.paginationall.currentPage = 1
      }
      const res = await listVideoReviewByOperator({
        pageNum: this.paginationall.currentPage,
        pageSize: this.paginationall.pageSize,
        needCount: true,
        className: this.classNameValue,
        sid: this.studentId,
        type:'补课视频',
        statusSet: this.seleStute ? [this.seleStute] : null
      })
      this.totalall = res.body.total
      this.dataListMyaudit = res.body.list
    },

    // 批量通过
    async enableAccount () {
      if (this.multipleSelection.length !== 0) {
        let enableList = []
        this.multipleSelection.map((item, index) => {
          item.attachmentId.split(',').map((ele, idx) => {
            enableList.push({
              attachmentId: ele,
              userId: item.userId,
              status: '已通过',
              startTime: item.startTime,
              endTime: item.endTime
            })
          })
        })
        // console.log('enableList', enableList)
        await reviewAttachmentBat(enableList)
        window.$msg('操作成功')
        this.getClassListAll(1)
      } else {
        window.$msg('请先选择数据', 2)
      }
    },

    // 批量拒绝
    async disabledAccount () {
      if (this.multipleSelection.length !== 0) {
        let enableList = []
        this.multipleSelection.map((item, index) => {
          item.attachmentId.split(',').map((ele, idx) => {
            enableList.push({
              attachmentId: ele,
              userId: item.userId,
              status: '未通过',
              startTime: item.startTime,
              endTime: item.endTime
            })
          })
        })
        await reviewAttachmentBat(enableList)
        window.$msg('操作成功')
        this.getClassListAll(1)
      } else {
        window.$msg('请先选择数据', 2)
      }
    },
    search () {
      if(this.activeName == 'first') {
        this.getClassListAll(2)
      }else if(this.activeName == 'second') {
        this.getlistVideoReviewByOperator(2)
      }

    },
    reset () {
      this.classNameValue = ''
      this.seleStute = ''
      this.studentId = ''
    },
    // 一键开放
    oneKeyOpen () {
      this.keyOpenDialog = true
    },
    handleSeleChange (val) {
      this.multipleSelection = val
      // console.log('handleSeleChange', this.multipleSelection)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
/deep/ .el-table {
  position: relative;
  width: 100%;
  max-width: 100%;
}
/deep/ .el-icon--right {
  margin-left: 5px;
}
</style>
