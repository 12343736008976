<template>
  <el-dialog :visible.sync="show" :before-close="close" :close-on-click-modal="false" top="7vh">
    <div slot="title" class="dia-tit">
      <i></i>
      <span>一键开放视频</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="排课时间段：" prop="lessonDate">
        <el-date-picker
          v-model="formData.lessonDate"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="视频有效期：" prop="videoDate">
        <el-date-picker
          v-model="formData.videoDate"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择视频到期时间"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="save()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { setAttachmentToUserBat } from '@/api/senate/lesson'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {
        lessonDate: '',
        videoDate: ''
      },
      rules: {
        lessonDate: [
          { required: true, message: '请选择排课时间段', trigger: 'blur' }
        ],
        videoDate: [
          { required: true, message: '请选择视频到期时间', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },

    save () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          setAttachmentToUserBat({
            minTime: this.formData.lessonDate[0],
            maxTime: this.formData.lessonDate[1],
            expiresTime: this.formData.videoDate
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('开放成功')
              this.close()
              this.$emit('getClassListAll')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
