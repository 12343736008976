<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <el-date-picker
      v-model="chooseDate"
      type="datetimerange"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00']"
      @change="getQueryDate"
    ></el-date-picker>
    <!-- <el-date-picker
      v-model="chooseDate"
      type="datetimerange"
      align="right"
      @change="getQueryDate"
      unlink-panels
      range-separator="-"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00']"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    ></el-date-picker> -->

    <el-row slot="footer">
      <el-button type="primary" @click="close" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { reviewAttachmentBat } from '@/api/senate/lesson'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editCourseData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },

  data () {
    return {
      chooseDate: [],
      AttachmentArray: []
    }
  },
  methods: {
    openDialog () {
      this.chooseDate = []
      this.AttachmentArray = []
      this.chooseDate.push(this.editCourseData.startTime)
      this.chooseDate.push(this.editCourseData.endTime)
    },
    getQueryDate () {
      // console.log('editCourseData', this.editCourseData)
      let attachmentId = []
      attachmentId = this.editCourseData.attachmentId.split(',')
      attachmentId.forEach(element => {
        console.log('element', element)
        this.AttachmentArray.push({
          attachmentId: element,
          userId: this.editCourseData.userId,
          status: this.editCourseData.status,
          startTime: this.chooseDate[0],
          endTime: this.chooseDate[1]
        })
      })
      console.log('AttachmentArray', this.AttachmentArray)
      reviewAttachmentBat(this.AttachmentArray).then(res => {
        if (res.state === 'success') {
          window.$msg('修改成功')
          this.$emit('close')
          this.$emit('updateView')
        }
      })
    },

    // 关闭
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
